import React from "react";
import { graphql } from "gatsby";
import RenderBlocks from "../../blocks/renderBlocks";
import PageLayout from "../../layout/PageLayout";
import BlockLayout from "../../blocks/BlockLayout";

const page = ({ data }) => {
  const { page } = data;
  const {
    title,
    pageOptions,
    contentBlocks,
  } = page;
  const blocks = contentBlocks?.blocks;
  const headerPosition = pageOptions?.hidePageTitle ? 'fixed' : 'sticky'
  return (
    <PageLayout data={data} headerPosition={headerPosition}>
        {pageOptions ? !pageOptions.hidePageTitle && <BlockLayout heading={title} pageTitle={true} /> : <BlockLayout heading={title} pageTitle={true} />}
        {blocks && <RenderBlocks blocks={blocks} />}
    </PageLayout>
  );
};

export default page;

export const query = graphql`
  query page($id: String!) {
    page: wpContentNode(id: { eq: $id }) {
      ... on WpPage {
        ...PageContent
      }
      ... on WpWork {
        ...WorkContent
      }
      ... on WpClient {
        ...ClientContent
      }
      ... on WpPost {
        ...PostContent
      }
    }
  }
`;
